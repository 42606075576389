import { jwtDecode } from 'jwt-decode';

/**
 * Obtiene el rol del usuario a partir del token JWT almacenado en localStorage.
 * @returns {string|null} El rol del usuario o null si no se puede obtener.
 */
export function getUserRoleFromToken() {
    const token = localStorage.getItem('token'); // Asumiendo que el token está en localStorage
    if (!token) return null;

    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.role; // Extrae el rol del token
    } catch (error) {
        console.error("Error decoding token:", error);
        return null;
    }
}

/**
 * Verifica si el token JWT está expirado.
 * @returns {boolean} True si el token está expirado, False de lo contrario.
 */
export function isTokenExpired() {
    const token = localStorage.getItem('token');
    if (!token) return true;

    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.exp * 1000 < Date.now(); // Verifica la fecha de expiración
    } catch (error) {
        console.error("Error decoding token:", error);
        return true;
    }
}
