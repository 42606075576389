import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";

const ClientModal = ({ show, setShow, onClientAdded }) => {
    const [categoryIva, setCategoryIva] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [fullName, setFullName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);


    const resetFields = () => {
        setCategoryIva('');
        setCompanyName('');
        setFullName('');
        setDocumentType('');
        setDocumentNumber('');
        setAddress('');
        setPhone('');
        setEmail('');
        setIsFormValid(true);
    };

    const handleClose = () => {
        resetFields();
        setShow(false);
    };


    const handleSaveClient = async () => {

        if (!categoryIva || !companyName || !fullName || !documentType || !documentNumber) {
            setIsFormValid(false);
            return;
        }

        const clientData = {
            categoryIva,
            companyName,
            fullName,
            documentType,
            documentNumber,
            address,
            phone,
            email,
        };

        axios.post(process.env.REACT_APP_API_ADD_CLIENT_ROUTE, clientData, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Guardado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                const newClient = {
                    value: response,
                    label: companyName ? `${fullName} (${companyName})` : fullName,
                };
                handleClose();
                onClientAdded(newClient);
            })
            .catch(error => {
                console.error('Error al guardar el cliente:', error);
                setError('Error al guardar el cliente. Inténtalo de nuevo.');
            });

        setIsFormValid(true);
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header>
                <h1 className="modal-title titleModalAddClient">
                    Agregar Cliente
                </h1>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </Modal.Header>
            <Modal.Body>
                {error && <div className="alert alert-danger">{error}</div>} {/* Mostrar error */}
                <Form onSubmit={handleSaveClient}>
                    <Form.Group className="mb-3" controlId="categoryIva">
                        <Form.Label className="col-form-label">Categoría IVA:</Form.Label>
                        <Form.Select value={categoryIva}
                                     onChange={(e) => setCategoryIva(e.target.value)}
                                     className={!isFormValid && !categoryIva ? 'is-invalid' : ''}
                        >
                            <option value="">Selecciona una categoría</option>
                            <option value="RESP_INSCRIPTO">Responsable Inscripto</option>
                            <option value="MONOTRIBUTISTA">Monotributista</option>
                            <option value="CONSUMIDOR_FINAL">Consumidor Final</option>
                            <option value="IVA_EXENTO">IVA Exento</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="companyName">
                        <Form.Label className="col-form-label">Nombre de la Empresa:</Form.Label>
                        <Form.Control
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            className={!isFormValid && !companyName ? 'is-invalid' : ''}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Label className="col-form-label">Nombre Completo:</Form.Label>
                        <Form.Control
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className={!isFormValid && !fullName ? 'is-invalid' : ''}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="documentType">
                        <Form.Label className="col-form-label">Tipo de Documento:</Form.Label>
                        <Form.Select
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            className={!isFormValid && !documentType ? 'is-invalid' : ''}
                        >
                            <option value="">Selecciona un tipo de documento</option>
                            <option value="CUIT">CUIT</option>
                            <option value="DNI">DNI</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="documentNumber">
                        <Form.Label className="col-form-label">Número de Documento:</Form.Label>
                        <Form.Control
                            type="text"
                            value={documentNumber}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    setDocumentNumber(value);
                                }
                            }}
                            className={!isFormValid && !documentNumber ? 'is-invalid' : ''}
                        />
                    </Form.Group>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label className="col-form-label">Dirección:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label className="col-form-label">Teléfono:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={phone}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            setPhone(value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label className="col-form-label">Email:</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSaveClient}>
                    Guardar Cliente
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ClientModal;
