import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button, Table, Pagination, Modal, Form} from "react-bootstrap";
import { FaPlus, FaSearch, FaTimes } from "react-icons/fa";
import {FiEdit} from "react-icons/fi";
import ClientModal from "./ClientModal";
import axios from "axios";
import Swal from 'sweetalert2';
import {RequestQuote, TrashCan, View, ViewOff} from "@carbon/icons-react"; // El modal que ya creaste para agregar cliente

const ClientList = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showClientModal, setShowClientModal] = useState(false);

    const [clients, setClients] = useState([]);

    // Definir el estado para los campos del cliente en el componente
    const [clientIdEdit, setClientIdEdit] = useState(null);
    const [clientFullNameEdit, setClientFullNameEdit] = useState('');
    const [clientCompanyNameEdit, setClientCompanyNameEdit] = useState('');
    const [clientEmailEdit, setClientEmailEdit] = useState('');
    const [clientPhoneEdit, setClientPhoneEdit] = useState('');
    const [clientAddressEdit, setClientAddressEdit] = useState('');
    const [clientDocumentTypeEdit, setClientDocumentTypeEdit] = useState('');
    const [clientDocumentNumberEdit, setClientDocumentNumberEdit] = useState('');
    const [clientCategoryIvaEdit, setClientCategoryIvaEdit] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [showModalEditClient, setShowModalEditClient] = useState(false);

    const [errors, setErrors] = useState({});

    function validateFields() {
        const newErrors = {};
        if (!clientFullNameEdit) newErrors.clientFullNameEdit = 'El nombre completo es obligatorio';
        if (!clientCompanyNameEdit) newErrors.clientCompanyNameEdit = 'El nombre de la empresa es obligatorio';
        if (!clientEmailEdit) newErrors.clientEmailEdit = 'El email es obligatorio';
        if (!clientPhoneEdit) newErrors.clientPhoneEdit = 'El teléfono es obligatorio';
        if (!clientAddressEdit) newErrors.clientAddressEdit = 'La dirección es obligatoria';
        if (!clientDocumentTypeEdit) newErrors.clientDocumentTypeEdit = 'El tipo de documento es obligatorio';
        if (!clientDocumentNumberEdit) newErrors.clientDocumentNumberEdit = 'El número de documento es obligatorio';
        if (!clientCategoryIvaEdit) newErrors.clientCategoryIvaEdit = 'La categoría de IVA es obligatoria';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    function saveEditClient() {
        if (validateFields()) {
            let clientEdit = {
                id: clientIdEdit,
                fullName: clientFullNameEdit,
                companyName: clientCompanyNameEdit,
                email: clientEmailEdit,
                phone: clientPhoneEdit,
                address: clientAddressEdit,
                documentType: clientDocumentTypeEdit,
                documentNumber: clientDocumentNumberEdit,
                categoryIva: clientCategoryIvaEdit,
            };

            axios.put(process.env.REACT_APP_API_CLIENTS_ROUTE, clientEdit, {
                withCredentials: true
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Cliente editado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    setShowModalEditClient(false);
                    cleanInputsEditClient();
                    getPage();
                })
                .catch(error => {
                    alertErrorConMensaje(error.response.data.errorMsg);
                });
        }
    }

    const handleSearch = () => {
        setIsLoading(true);
        // Lógica para buscar clientes
        setIsLoading(false);
    };

    const handleClear = () => {
        setSearchTerm("");
        // Lógica para limpiar búsqueda
    };

    const handleModalClose = () => {
        setShowClientModal(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    function alertErrorConMensaje(mensaje) {
        Swal.fire({
            title: 'Error',
            text: mensaje,
            icon: 'error',
            showConfirmButton: true,
            allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
            allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
            allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
        });
    }


    const getPage = () => {
        axios
            .get(process.env.REACT_APP_API_CLIENTS_PAGE_ROUTE, {
                withCredentials: true,
                params: {
                    page: currentPage,
                    filter: searchTerm,
                },
            })
            .then(response => {
                setClients(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements);
                setTotalElements(response.data.totalElements);
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleAddClient = () => {
        setShowClientModal(true);
        getPage();
    };

    function onEdit(client) {
        setClientIdEdit(client.id);
        setClientFullNameEdit(client.fullName);
        setClientCompanyNameEdit(client.companyName);
        setClientEmailEdit(client.email);
        setClientPhoneEdit(client.phone);
        setClientAddressEdit(client.address);
        setClientDocumentTypeEdit(client.documentType);
        setClientDocumentNumberEdit(client.documentNumber);
        setClientCategoryIvaEdit(normalizeCategoryIva(client.categoryIva));
    }

    function normalizeCategoryIva(categoryIva) {
        switch (categoryIva) {
            case 'Responsable Inscripto': return 'RESP_INSCRIPTO';
            case 'Monotributista': return 'MONOTRIBUTISTA';
            case 'Consumidor Final': return 'CONSUMIDOR_FINAL';
            case 'IVA Exento': return 'IVA_EXENTO';
            default: return '';
        }
    }

    function onDelete(client) {
        Swal.fire({
            title: '¿Estás seguro de eliminar el cliente?',
            text: 'Al eliminar al cliente, se eliminarán todos sus datos asociados.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_CLIENTS_ROUTE + '/' + client.id, {
                    withCredentials: true
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Cliente eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getPage(); // Llama a la función para refrescar la lista de clientes.
                    })
                    .catch(error => {
                        alertErrorConMensaje(error.response.data.errorMsg);
                    });
            }
        });
    }

    const enableClient = (clientId) => {
        axios.put(process.env.REACT_APP_API_CLIENTS_ENABLED_AND_DISABLED_ROUTE + '/' + clientId, {}, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Cliente activado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage(); // Actualiza la lista de clientes
            })
            .catch(error => {
                alertErrorConMensaje(error.response.data.errorMsg);
            });
    };

    const disableClient = (clientId) => {
        axios.put(process.env.REACT_APP_API_CLIENTS_ENABLED_AND_DISABLED_ROUTE + '/' + clientId, {}, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Cliente desactivado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage(); // Actualiza la lista de clientes
            })
            .catch(error => {
                alertErrorConMensaje(error.response.data.errorMsg);
            });
    };

    useEffect(() => {
        getPage();
    }, [currentPage, isLoading]);

    function cleanInputsEditClient() {
        setClientFullNameEdit('');
        setClientCompanyNameEdit('');
        setClientEmailEdit('');
        setClientPhoneEdit('');
        setClientAddressEdit('');
        setClientDocumentTypeEdit('');
        setClientDocumentNumberEdit('');
        setClientCategoryIvaEdit('');
        setErrors({});
    }

    return (
        <div className="clientListContainer">
            <Container className="mt-3">
                <Row>
                    <Col>
                        <h1 className="titleProducts">Clientes</h1>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-3">
                <Row>
                    <Col>
                        <input
                            type="text"
                            className="searchTerm"
                            placeholder="Nombre del cliente"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Col>
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button variant="primary" className="me-1" onClick={handleSearch}>
                            <FaSearch />
                        </Button>
                        <Button variant="primary" className="ms-1" onClick={handleClear}>
                            <FaTimes />
                        </Button>
                    </Col>
                </Row>
            </Container>

            <div className="ms-3 me-3 mt-3">
                <Table responsive className="table-striped">
                    <thead>
                    <tr className="trTable">
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "5%",
                            color: "#ffffff"
                        }} scope="col-1">ID
                        </th>
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "15%",
                            color: "#ffffff"
                        }} scope="col-1">Nombre Completo
                        </th>
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "15%",
                            color: "#ffffff"
                        }} scope="col-1">Razon Social
                        </th>
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "10%",
                            color: "#ffffff"
                        }} scope="col-1">Tipo IVA
                        </th>
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "10%",
                            color: "#ffffff"
                        }} scope="col-1">Documento
                        </th>
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "10%",
                            color: "#ffffff"
                        }} scope="col-1">Estado
                        </th>
                        <th className="text-center fw-bold" style={{
                            backgroundColor: "rgb(235, 120, 107)",
                            width: "5%",
                            color: "#ffffff"
                        }} scope="col-1"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map((client) => (
                        <tr key={client.id}>
                            <td className="text-alig-center fw-bold">{client.id}</td>
                            <td className="text-alig-center">{client.fullName}</td>
                            <td className="text-alig-center">{client.companyName}</td>
                            <td className="text-alig-center">{client.categoryIva}</td>
                            <td className="text-alig-center">{client.documentType} - {client.documentNumber}</td>
                            <td className="text-alig-center">{client.status ? "Activo" : "Inactivo"}</td>
                            <td width="5%">
                                <div className="colBtnsActionClient">
                                    {/* Botón Editar */}
                                        <button
                                            className="btn btnEdit"
                                            onClick={() => {
                                                onEdit(client);
                                                setShowModalEditClient(true);
                                            }}
                                        >
                                            <RequestQuote className="btnEdit" size="24"/>
                                        </button>
                                    {/* Botón Activar/Inactivar */}
                                    {client.status ? (
                                        <button className="btn btnActiveInactive"
                                                    onClick={() => disableClient(client.id)}>
                                                <View className="btnActiveInactive" size="24"/>
                                            </button>
                                        ) : (
                                            <button className="btn btnActiveInactive"
                                                    onClick={() => enableClient(client.id)}>
                                                <ViewOff className="btnActiveInactive" size="24"/>
                                            </button>
                                        )}

                                    {/* Botón Eliminar */}
                                        <button className="btn btnDelete"
                                                onClick={() => onDelete(client)}>
                                            <TrashCan className="btnDelete" size="24"/>
                                        </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || clients.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || clients.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>
                    <Col className="text-alig-center">
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={handleAddClient}
                        >
                            Agregar
                        </Button>
                    </Col>
                </Row>
            </Container>

            {/* Modal para agregar cliente */}
            <ClientModal
                show={showClientModal}
                setShow={handleModalClose}
                onClientAdded={getPage}
            />

            <Modal size="lg" show={showModalEditClient} onHide={() => setShowModalEditClient(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title titleModalEditClient" id="modaleEditProductLabel">
                        Editar cliente
                    </h1>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setShowModalEditClient(false);
                            cleanInputsEditClient();
                        }}
                    ></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <form>
                        {/* Seleccionable para Categoría de IVA */}
                        <div className="mb-3">
                            <label className="form-label col-form-label">Categoría de IVA:</label>
                            <Form.Select
                                className={`form-control ${errors.clientCategoryIvaEdit ? 'is-invalid' : ''}`}
                                value={clientCategoryIvaEdit}
                                onChange={(e) => setClientCategoryIvaEdit(e.target.value)}
                            >
                                <option value="">Selecciona una categoría</option>
                                <option value="RESP_INSCRIPTO">Responsable Inscripto</option>
                                <option value="MONOTRIBUTISTA">Monotributista</option>
                                <option value="CONSUMIDOR_FINAL">Consumidor Final</option>
                                <option value="IVA_EXENTO">IVA Exento</option>
                            </Form.Select>
                            {errors.clientCategoryIvaEdit &&
                                <div className="invalid-feedback">{errors.clientCategoryIvaEdit}</div>}
                        </div>
                        {[
                            {
                                label: 'Nombre de la empresa:',
                                value: clientCompanyNameEdit,
                                setter: setClientCompanyNameEdit,
                                error: errors.clientCompanyNameEdit
                            },
                            {
                                label: 'Nombre completo:',
                                value: clientFullNameEdit,
                                setter: setClientFullNameEdit,
                                error: errors.clientFullNameEdit
                            },
                            {
                                label: 'Dirección:',
                                value: clientAddressEdit,
                                setter: setClientAddressEdit,
                                error: errors.clientAddressEdit
                            },
                            {
                                label: 'Teléfono:',
                                value: clientPhoneEdit,
                                setter: setClientPhoneEdit,
                                error: errors.clientPhoneEdit
                            },
                            {
                                label: 'Email:',
                                value: clientEmailEdit,
                                setter: setClientEmailEdit,
                                error: errors.clientEmailEdit
                            },
                        ].map((field, index) => (
                            <div className="mb-3" key={index}>
                                <label className="form-label col-form-label">{field.label}</label>
                                <input
                                    type="text"
                                    className={`form-control ${field.error ? 'is-invalid' : ''}`}
                                    value={field.value}
                                    onChange={(e) => field.setter(e.target.value)}
                                />
                                {field.error && <div className="invalid-feedback">{field.error}</div>}
                            </div>
                        ))}
                        {/* Seleccionable para Tipo de Documento */}
                        <div className="mb-3">
                            <label className="form-label col-form-label">Tipo de documento:</label>
                            <Form.Select
                                className={`form-control ${errors.clientDocumentTypeEdit ? 'is-invalid' : ''}`}
                                value={clientDocumentTypeEdit}
                                onChange={(e) => setClientDocumentTypeEdit(e.target.value)}
                            >
                                <option value="">Selecciona un tipo de documento</option>
                                <option value="CUIT">CUIT</option>
                                <option value="DNI">DNI</option>
                            </Form.Select>
                            {errors.clientDocumentTypeEdit &&
                                <div className="invalid-feedback">{errors.clientDocumentTypeEdit}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label col-form-label">Número de documento:</label>
                            <Form.Control
                                type="text"
                                value={clientDocumentNumberEdit}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setClientDocumentNumberEdit(value);
                                    }
                                }}
                            />
                            {errors.clientDocumentNumberEdit &&
                                <div className="invalid-feedback">{errors.clientDocumentNumberEdit}</div>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button
                        id="btnCloseModalEdit"
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                            cleanInputsEditClient();
                            setShowModalEditClient(false);
                        }}
                    >
                        Cerrar
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            saveEditClient();
                        }}
                    >
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ClientList;
