import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import ClientModal from "./ClientModal";
import axios from 'axios';
import Swal from 'sweetalert2';

const InvoiceModal = ({ showModal, setShowModal, orderId, orderClientId}) => {
    const [selectedClient, setSelectedClient] = useState(orderClientId || '');
    const [selectedPointOfSale, setSelectedPointOfSale] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [clients, setClients] = useState([]);
    const [pointsOfSale, setPointsOfSale] = useState([]);
    const [showClientModal, setShowClientModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);

    const handleClientAdded = (newClient) => {
        const clientId = newClient.value.data;
        const clientLabel = newClient.label;
        setClients((prevClients) => [
            ...prevClients,
            { id: clientId, fullName: clientLabel, companyName: '' }
        ]);

        setSelectedClient(clientId);
    };

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_CLIENTS_ROUTE, {withCredentials: true});
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        const fetchPointsOfSale = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_POINT_OF_SALE_ROUTE, {withCredentials: true});
                setPointsOfSale(response.data);
            } catch (error) {
                console.error('Error fetching points of sale:', error);
            }
        };

        fetchClients();
        fetchPointsOfSale();
    }, []);


    const handleSubmit = () => {
        const isClientValid = selectedClient !== '';
        const isPointOfSaleValid = selectedPointOfSale !== '';

        //se setea la fecha de vencimiento hasta que se mande en el front
        setDueDate(new Date().toISOString().split('T')[0]);
        const isDueDateValid = dueDate !== '';

        setIsFormValid(isClientValid && isPointOfSaleValid && isDueDateValid);

        if (!isClientValid || !isPointOfSaleValid || !isDueDateValid) {
            return;
        }

        // Crear el objeto InvoiceRequestDTO
        const invoiceRequestDTO = {
            dueDate: dueDate,
            clientId: selectedClient,
            pointOfSale: selectedPointOfSale,
            invoiceType: 'INVOICE_C', // "Factura C" es la única opción habilitada por ahora
        };

        axios
            .post(`${process.env.REACT_APP_API_AUTHORIZER_ROUTE}${orderId}`, invoiceRequestDTO, { withCredentials: true })
            .then((response) => {
                // Verificamos si existen errores en la respuesta
                if (response.data.errors && Object.keys(response.data.errors).length > 0) {
                    let errorMessage = 'Errores en la generación de la factura:\n';

                    Object.entries(response.data.errors).forEach(([code, message]) => {
                        errorMessage += `Código: ${code}, Mensaje: ${message}\n`;
                    });

                    // Agregamos las notas al mensaje de error
                    if (response.data.notes && response.data.notes.length > 0) {
                        errorMessage += '\nNotas:\n';
                        response.data.notes.forEach(note => {
                            errorMessage += `${note}\n`;
                        });
                    }
                    Swal.fire("Error", errorMessage, "error");
                } else {
                    // Si no hay errores, mostramos los detalles de la factura
                    const { invoiceNumber, CAE, dueDate } = response.data;
                    Swal.fire("Factura generada", `Número: ${invoiceNumber}, CAE: ${CAE}, Fecha de vencimiento: ${dueDate}`, "success");
                }
            })
            .catch((error) => {
                // En caso de error en la solicitud HTTP
                Swal.fire("Error", 'Hubo un error al generar la factura. Por favor, inténtalo nuevamente.', "error");
            });

    };

    const handleClose = () => {
        setDueDate('');
        setSelectedPointOfSale('');
        setSelectedClient(orderClientId);
        setIsFormValid(true);
        setShowModal(false);
    };

    const handleModalClose = () => {
        setShowClientModal(false);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Pedido a Facturar</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Row>
                            {/* Punto de Venta */}
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="pointOfSale">
                                    <Form.Label>Punto de Venta:</Form.Label>
                                    <Form.Select
                                        value={selectedPointOfSale}
                                        onChange={(e) => {
                                            setSelectedPointOfSale(e.target.value);
                                            setIsFormValid(e.target.value !== '' && selectedClient !== '' && dueDate !== '');
                                        }}
                                        isInvalid={!isFormValid && !selectedPointOfSale}
                                    >
                                        <option value="">Selecciona un punto de venta</option>
                                        {pointsOfSale.map((point) => (
                                            <option key={point.id} value={point.id}>
                                                {point.pointOfSale}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {/* Facturas Permitidas (Factura A, B, C) */}
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="invoiceType">
                                    <Form.Label>Factura Permitida:</Form.Label>
                                    <Form.Select
                                        value="Factura C" // La única permitida por ahora
                                        disabled // Deshabilitado por defecto
                                    >
                                        <option value="Factura A">FACTURA A</option>
                                        <option value="Factura B">FACTURA B</option>
                                        <option value="Factura C">FACTURA C</option> {/* Preseleccionado */}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {/* Comentado el código que maneja la fecha de vencimiento hasta que se necesite */}
                            {/*
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="dueDate">
                                    <Form.Label>Fecha de Vencimiento:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dueDate}
                                        onChange={(e) => {
                                            setDueDate(e.target.value);
                                            setIsFormValid(e.target.value !== '' && selectedPointOfSale !== '' && selectedClient !== '');
                                        }}
                                        isInvalid={!isFormValid && !dueDate}
                                    />
                                </Form.Group>
                            </Col>
                            */}
                        </Row>

                        <Form.Group className="mb-3" controlId="client">
                            <Form.Label>Cliente:</Form.Label>
                            <div className="d-flex align-items-center">
                                <Form.Select
                                    value={selectedClient}
                                    onChange={(e) => {
                                        setSelectedClient(e.target.value);
                                        setIsFormValid(e.target.value !== '' && selectedPointOfSale !== '' && dueDate !== '');
                                    }}
                                    className="me-2"
                                    isInvalid={!isFormValid && !selectedClient}
                                >
                                    <option value="">Selecciona un cliente</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>
                                            {client.fullName} {client.companyName ? `(${client.companyName})` : ''}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Button variant="outline-primary" onClick={() => setShowClientModal(true)}>
                                    <FaPlus /> Agregar Cliente
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Facturar
                    </Button>
                </Modal.Footer>
            </Modal>
           <ClientModal show={showClientModal} setShow={handleModalClose} onClientAdded={handleClientAdded} />
    </>
    );
};

export default InvoiceModal;
