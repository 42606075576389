import React, {useEffect, useState} from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import QRCode from 'qrcode';



/**
 * Generates a styled PDF invoice.
 * @param {Object} invoiceData - The data for the invoice.
 * @param {string} invoiceData.customerName - Name of the customer.
 * @param {string} invoiceData.customerAddress - Address of the customer.
 * @param {string} invoiceData.invoiceNumber - Invoice number.
 * @param {string} invoiceData.invoiceDate - Invoice date.
 * @param {Array} invoiceData.items - List of items in the invoice.
 * @param {string} invoiceData.footerNote - Footer note for the invoice.
 */

export default function InvoicePDFGenerator() {
    const location = useLocation();
    const navigate = useNavigate();

    const [invoiceData, setInvoiceData] = useState({
                                                                "id": 1,
                                                                "invoiceType": "A",
                                                                "number": "9988777",
                                                                "pointOfSale": 2,
                                                                "invoiceStatus": "PENDING",
                                                                "totalAmount": 12221,
                                                                "issueDate": "22-12-2024",
                                                                "authorizationDate": "22-12-2024",
                                                                "customerShippingDate": "22-12-2024",
                                                                "expirationDate": "22-12-2024",
                                                                "caeDate": "26-12-2024",
                                                                "numberCae": "34243234243",
                                                                "invoiceCode": "80"
                                                                });
    const [shopData, setShopData] = useState({
                                                             "id": 1,
                                                            "name": "Tienda Karen",
                                                            "address": "Calle Falsa 123, Humboldt - Santa Fe",
                                                            "phone": null,
                                                            "state": null,
                                                            "location": null,
                                                            "businessName": "Tienda SA Karen",
                                                            "pathLogo": null,
                                                            "pathQr": null,
                                                            "principalColorShop": null,
                                                            "image": null,
                                                            "imageFormat": null,
                                                            "modules": null,
                                                            "shopModuleActiveDTO": null,
                                                            "configModuleOrderWhatsappDTO": null,
                                                            "latitude": null,
                                                            "longitude": null,
                                                            "taxCode": null,
                                                            "categoryIva": "MONOTRIBUTISTA",
                                                            "uuid": null
                                                    });

    const [qrImage, setQrImage] = useState(null);

    useEffect(() => {
        console.log(location.state.invoiceData);
        if (location.state?.invoiceData) {
            setInvoiceData(location.state.invoiceData);
            setShopData(location.state.invoiceData.shop);

            const qrData = `Factura: ${invoiceData.number} - ${invoiceData.issueDate}`;
            QRCode.toDataURL(qrData, { color: { dark: '#000000', light: '#ffffff' } })  // Set QR color to black
                .then(url => {
                    setQrImage(url);  // Set the generated QR image URL
                })
                .catch(err => console.error("Error generating QR:", err));
        }

    }, [location, invoiceData]);

    if (!invoiceData || !qrImage) {
        Swal.fire("Error", "No se encontraron datos de la factura o el QR no se generó.", "error");
        navigate('/orders');
        return;
    }

    const doc = new jsPDF();


    // Datos del emisor
    const emisor = shopData
        ? {
            razonSocial: shopData.businessName || "-",
            direccion: shopData.address || "-",
            condicionIVA: shopData.categoryIva || "-"
        }
        : {};

    // products
    const products = invoiceData.details?.map(item => ({
        description: item.description || 'Sin descripción',
        quantity: item.quantity || 0,
        price: parseFloat(item.unitPrice) || 0,
        subtotalSinIVA: parseFloat(item.subTotalPriceSinIVA) || 0,
        subtotalConIVA: parseFloat(item.subTotalPriceConIVA) || 0,
        total: `$${(item.totalPrice.toFixed(2))}`,
        alicuotaIVA : item.alicuotaIVA,
    })) || [];


    // Totales
    const iva = products.subTotalPriceSinIVA * 0.21;
    const total = parseFloat(products.subTotalPriceSinIVA + iva);

    const cae = invoiceData.numberCae;
    const fechaVtoCAE = invoiceData.caeDate;

    // Dimensiones y márgenes
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;

    // Establecer el grosor de las líneas
    doc.setLineWidth(0.35);

    // Marco principal
    doc.rect(margin, margin, pageWidth - 2 * margin, 280); // Marco principal ajustado

    // Recuadro superior "ORIGINAL"
    const headerHeight = 8;
    const originalY = margin + 2;

    // Definir el recuadro de "ORIGINAL" con el ancho de toda la página
    const originalBoxWidth = pageWidth - 2 * margin - 4; // Ancho del recuadro para "ORIGINAL" igual al ancho de la página
    const originalBoxHeight = 10; // Alto del recuadro
    const originalBoxX = margin +2    ; // Inicia en el margen izquierdo
    const originalBoxY = originalY + 2; // Justo debajo del margen superior

    // Establecer grosor de línea para el recuadro de "ORIGINAL"
    doc.setLineWidth(0.5); // Grosor de línea 0.5
    doc.setDrawColor(0, 0, 0); // Color negro para el borde del recuadro
    doc.rect(originalBoxX, originalBoxY, originalBoxWidth, originalBoxHeight); // Dibujar el recuadro

    // Texto "ORIGINAL" en negrita y tamaño grande, centrado en el recuadro
    doc.setFontSize(14); // Tamaño más grande para "ORIGINAL"
    doc.setFont("Times", "bold"); // Negrita
    doc.text('ORIGINAL', pageWidth / 2, originalBoxY + originalBoxHeight / 2 + 3, { align: 'center' });

    // Recuadro "Cód" encima del encabezado, superponiéndose
    const cabeceraY = originalY + headerHeight + 5; // Posición ajustada para que quede encima del encabezado
    const smallBoxWidth = 11;
    const smallBoxHeight = 10;
    const smallBoxX = pageWidth / 2 - smallBoxWidth / 2;
    const smallBoxY = cabeceraY * 1.7 - smallBoxHeight * 1.7; // Se superpone sobre el encabezado

    // Ajustar fuente para Tipo factura (más grande)
    doc.setFontSize(14);
    doc.text(invoiceData.invoiceType, smallBoxX + 3, smallBoxY + 5);

    // Establecer el color de relleno como blanco
    doc.setFillColor(255, 255, 255); // Fondo blanco
    doc.setDrawColor(0, 0, 0); // Borde negro

    // Dibujar el rectángulo con relleno blanco
    doc.rect(smallBoxX, smallBoxY, smallBoxWidth, smallBoxHeight, 'FD'); // F: relleno, D: dibujo del borde

    // Ajustar texto dentro del recuadro
    doc.setFont("Times", "bold");
    doc.setFontSize(14);
    doc.text(invoiceData.invoiceType, smallBoxX + 3, smallBoxY + 5);

    doc.setFontSize(8); // Tamaño más pequeño para "Cód. 8"
    doc.text('Cód. ' + invoiceData.invoiceCode, smallBoxX + 1, smallBoxY + 8); // Ajusta las posiciones para centrado

    // Recuadro del encabezado ajustado con grosor 0.5
    doc.setLineWidth(0.5); // Grosor de línea 0.5
    const cabeceraHeight = 50;
    doc.rect(margin + 2, cabeceraY, pageWidth - 2 * margin - 4, cabeceraHeight); // Marco cabecera

    // Ajustar la línea divisoria
    const startY = cabeceraY + 11; // Ajusta este valor para que empiece más abajo
    const endY = cabeceraY + cabeceraHeight ; // Ajusta este valor para que termine antes

    doc.line(pageWidth / 2, startY, pageWidth / 2, endY); // Línea divisoria ajustada

    // Nombre de la empresa (en negrita y grande)
    doc.setFontSize(14);
    doc.setFont("Times", "bold");
    doc.text(emisor.razonSocial, margin + 6, cabeceraY + 10);

    // Datos del emisor (títulos en negrita, valores en normal, más pequeños)
    doc.setFontSize(10);
    doc.setFont("Times", "bold");
    doc.text("Razón Social:", margin + 6, cabeceraY + 20);
    doc.setFont("Times", "normal");
    doc.text(emisor.razonSocial, margin + 28, cabeceraY + 20);

    doc.setFont("Times", "bold");
    doc.text("Dirección:", margin + 6, cabeceraY + 25);
    doc.setFont("Times", "normal");
    doc.text(emisor.direccion, margin + 23, cabeceraY + 25);

    doc.setFont("Times", "bold");
    doc.text("Condición IVA:", margin + 6, cabeceraY + 30);
    doc.setFont("Times", "normal");
    doc.text(emisor.condicionIVA, margin + 32, cabeceraY + 30);

    // Información del tipo de factura
    const facturaX = pageWidth / 2 + 6;
    doc.setFontSize(14); // Tamaño más grande para "Factura A"
    doc.setFont("Times", "bold"); // Negrita
    doc.text("FACTURA "+invoiceData.invoiceType, facturaX, cabeceraY + 10);

    doc.setFontSize(10); // Vuelve al tamaño normal para los demás datos
    doc.setFont("Times", "bold");
    doc.text("Punto de Venta:", facturaX, cabeceraY + 20);
    doc.setFont("Times", "normal");
    doc.text("000" + invoiceData.pointOfSale, facturaX + 25, cabeceraY + 20);

    doc.setFont("Times", "bold");
    doc.text("Nº Comprobante:", facturaX, cabeceraY + 25);
    doc.setFont("Times", "normal");
    doc.text(invoiceData.number, facturaX + 28, cabeceraY + 25);

    doc.setFont("Times", "bold");
    doc.text("Fecha de Emisión:", facturaX, cabeceraY + 30);
    doc.setFont("Times", "normal");
    doc.text(invoiceData.issueDate, facturaX + 28, cabeceraY + 30);

    const taxCode = shopData.taxCode ? String(shopData.taxCode) : "N/A";
    doc.setFont("Times", "bold");
    doc.text("CUIT:", facturaX, cabeceraY + 35);
    doc.setFont("Times", "normal");
    doc.text(taxCode, facturaX + 11, cabeceraY + 35);

    doc.setFont("Times", "bold");
    doc.text("Ingresos Brutos:", facturaX, cabeceraY + 40);
    doc.setFont("Times", "normal");
    doc.text(shopData.ingresosBrutos, facturaX + 26, cabeceraY + 40);

    doc.setFont("Times", "bold");
    doc.text("Fecha Inicio Actividad:", facturaX, cabeceraY + 45);
    doc.setFont("Times", "normal");
    doc.text(shopData.activityStartDate, facturaX + 36, cabeceraY + 45);

    // Información del receptor
    const receptorY = cabeceraY + cabeceraHeight + 4;
    const receptorHeight = 30;
    doc.setLineWidth(0.35);
    doc.rect(margin + 2, receptorY, pageWidth - 2 * margin - 4, receptorHeight); // No tiene grosor de línea aquí
    doc.setFont("Times", "bold");
    doc.text("Apellido y Nombre / Razón Social:", margin + 6, receptorY + 10);
    doc.setFont("Times", "normal");
    doc.text(invoiceData.client.fullName, margin + 60, receptorY + 10);

    doc.setFont("Times", "bold");
    doc.text("CUIT:", margin + 6, receptorY + 15);
    doc.setFont("Times", "normal");
    doc.text(invoiceData.client.documentNumber, margin + 17, receptorY + 15);

    doc.setFont("Times", "bold");
    doc.text("Domicilio:", margin + 6, receptorY + 20);
    doc.setFont("Times", "normal");
    doc.text(invoiceData.client.address, margin + 23, receptorY + 20);

    doc.setFont("Times", "bold");
    doc.text("Condición IVA:", margin + 6, receptorY + 25);
    doc.setFont("Times", "normal");
    doc.text(invoiceData.client.categoryIva, margin + 31, receptorY + 25);

    // products
    const productsY = receptorY + receptorHeight + 10;

    // Configurar la tipografía para todo el documento
    doc.setFont("Times", "normal");

// Tabla de products
    doc.autoTable({
        startY: productsY,
        head: [['Producto/servicio', 'Cant', 'Precio Unit.', 'SubTotal', 'Alicuota IVA', 'Subtotal c/IVA']], // Encabezado
        body: products.map(p => [p.description, p.quantity, p.price, p.subtotalSinIVA, p.alicuotaIVA, p.subtotalConIVA]),
        headStyles: {
            font: "Times", // Fuente personalizada
            fontStyle: "bold", // Estilo de fuente
            textColor: 0,
            lineWidth: 0.35, // Bordes para el encabezado
            lineColor: 0, // Color de la línea para el encabezado
            halign: 'center', // Centrado de los textos en el encabezado
            fillColor: [255, 255, 255], // Sin color de fondo en el encabezado
        },
        bodyStyles: {
            textColor: 0,
            lineWidth: 0, // Eliminar líneas en las celdas de los productos
            lineColor: 0,
            fillColor: [255, 255, 255], // Sin color de fondo en las celdas de productos
        },
        styles: {
            fontSize: 10,
            cellPadding: 3, // Asegurando que el texto se vea bien dentro de las celdas
            halign: 'left', // Alinear texto a la izquierda
        },
        theme: 'grid', // Usamos 'grid' para mantener la estructura de la tabla sin bordes en el cuerpo
        drawCell: function (cell, data) {
            // Bordes de la primera columna (Descripción)
            if (cell.column.index === 0) {
                cell.styles.lineWidth = 0.35;
                cell.styles.lineColor = [0, 0, 0]; // Bordes de la izquierda
            }

            // Bordes de la última columna (Subtotal)
            if (cell.column.index === 3) {
                cell.styles.lineWidth = 0.35;
                cell.styles.lineColor = [0, 0, 0]; // Bordes de la derecha
            }

            // Bordes inferiores en el último producto
            if (data.row.index === products.length - 1) {
                cell.styles.lineWidth = 0.35;
                cell.styles.lineColor = [0, 0, 0]; // Bordes inferiores
            }
        }
    });

    // Recuadro final con totales
    doc.setLineWidth(0.50);
    const finalY = doc.lastAutoTable.finalY + 30;
    const recuadroFinalHeight = 60;
    doc.rect(margin + 2, finalY, pageWidth - 2 * margin - 4, recuadroFinalHeight);


    // Extraer valores
    const numericSubTotal = parseFloat(invoiceData.netoGravado) || 0;
    const numericNoGravado = parseFloat(invoiceData.netoNoGravado) || 0;
    const otrosTributos = parseFloat(invoiceData.otrosTributos) || 0;
    const numericTotal = parseFloat(invoiceData.totalAmount) || 0;


    const Iva = {
        NO_GRAVADO: { id: 1, rate: "0.0" },
        EXENTO: { id: 2, rate: "0.0" },
        GRAVADO_TRES: { id: 3, rate: "0.0" },
        GRAVADO_CUATRO: { id: 4, rate: "0.105" },
        GRAVADO_CINCO: { id: 5, rate: "0.21" },
        GRAVADO_SEIS: { id: 6, rate: "0.27" },
        GRAVADO_OCHO: { id: 8, rate: "0.05" },
        GRAVADO_NUEVE: { id: 9, rate: "0.025" },
        GRAVADO_DIEZ: { id: 10, rate: "0.19" },
        GRAVADO_ONCE: { id: 11, rate: "0.22" },
        GRAVADO_DOCE: { id: 12, rate: "0.18" },
        GRAVADO_DIECISEIS: { id: 16, rate: "0.16" }
    };

    // IVA detallado usando las claves del enum
    const ivaDetails = invoiceData.ivaDetails || {};
    const iva27 = ivaDetails[Iva.GRAVADO_SEIS.id] || 0;    // IVA 27% (Iva.GRAVADO_SEIS => 6)
    const iva21 = ivaDetails[Iva.GRAVADO_CINCO.id] || 0;   // IVA 21% (Iva.GRAVADO_CINCO => 5)
    const iva105 = ivaDetails[Iva.GRAVADO_DIEZ.id] || 0;   // IVA 10.5% (Iva.GRAVADO_DIEZ => 10)
    const iva5 = ivaDetails[Iva.GRAVADO_OCHO.id] || 0;     // IVA 5% (Iva.GRAVADO_OCHO => 8)
    const iva25 = ivaDetails[Iva.GRAVADO_NUEVE.id] || 0;   // IVA 2.5% (Iva.GRAVADO_NUEVE => 9)
    const iva0 = ivaDetails[Iva.NO_GRAVADO.id] || 0;       // IVA 0% (Iva.NO_GRAVADO => 1)

    // Generación de texto en el PDF
    doc.setFontSize(12);
    doc.text(`Importe Neto Gravado: $${numericSubTotal.toFixed(2)}`, pageWidth - margin - 70, finalY + 10);
    doc.text(`Importe Neto No Gravado: $${numericNoGravado.toFixed(2)}`, pageWidth - margin - 70, finalY + 15);
    doc.text(`IVA 27%: $${iva27.toFixed(2)}`, pageWidth - margin - 70, finalY + 20);
    doc.text(`IVA 21%: $${iva21.toFixed(2)}`, pageWidth - margin - 70, finalY + 25);
    doc.text(`IVA 10,5%: $${iva105.toFixed(2)}`, pageWidth - margin - 70, finalY + 30);
    doc.text(`IVA 5%: $${iva5.toFixed(2)}`, pageWidth - margin - 70, finalY + 35);
    doc.text(`IVA 2,5%: $${iva25.toFixed(2)}`, pageWidth - margin - 70, finalY + 40);
    doc.text(`IVA 0%: $${iva0.toFixed(2)}`, pageWidth - margin - 70, finalY + 45);
    doc.text(`Importe Otros Tributos: $${otrosTributos.toFixed(2)}`, pageWidth - margin - 70, finalY + 50);
    doc.text(`Importe Total: $${numericTotal.toFixed(2)}`, pageWidth - margin - 70, finalY + 55);

    // CAE y fecha de vencimiento
    doc.text(`CAE Nº: ${cae}`, margin + 125, finalY + recuadroFinalHeight + 10);
    doc.text(`Fecha de Vto. de CAE: ${fechaVtoCAE}`, margin + 125, finalY + recuadroFinalHeight + 15);

    // Calcula la posición Y para colocar el QR en la parte inferior
    const pageHeight = doc.internal.pageSize.getHeight();
    const qrMarginBottom = 10; // Margen inferior para el QR
    const qrX = margin; // Alineado al margen izquierdo
    const qrY = pageHeight - qrMarginBottom - 40; // Ajusta la altura según el tamaño del QR

    // Agregar el QR a la parte inferior izquierda
    if (qrImage) {
        doc.addImage(qrImage, 'PNG', qrX, qrY, 40, 40); // Ajusta el tamaño 40x40 según tus necesidades
    }

    // Guardar el PDF
    doc.save('Factura_' + invoiceData.id +' .pdf');

    // Mostrar mensaje de éxito
    Swal.fire("Éxito", "Factura generada y descargada con éxito.", "success").then(() => {
        navigate('/orders');
    });
};
