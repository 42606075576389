const statusMessages = {
    RECEIVED: "Recibido",
    IN_PREPARATION: "En Preparación",
    DELIVERY_IN_PROGRESS: "En Camino",
    FINALIZED_NOT_AUTHORIZED: "Finalizado - No facturado",
    FINALIZED_AUTHORIZED: "Finalizado - Facturado",
    UPDATED: "Actualizado",
    CANCELLED: "Cancelado"

};

export default statusMessages;
