import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import '../App.css';
import './Login.css';
import Swal from 'sweetalert2';
import * as axiosService from '../services/AxiosService';

export default function Login(){

    const navigate = useNavigate();
    const versionApp = "V1.3.1";

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        document.title = 'QReate - Login';
      }, []);

    function handleSubmit(event) {

        event.preventDefault();

        const userData = {
            username: user,
            password: password
        };

        axios
            .post(process.env.REACT_APP_API_LOGIN_ROUTE, userData, {
                withCredentials: true, // Asegura el envío de cookies si el token está en cookies
            })
            .then((response) => {
                const token = response.data.token;
                if (token) {
                    localStorage.setItem('token', token);
                }
                navigate('/home');
            })
            .catch(() => {
                Swal.fire({
                    title: 'Ups! No pudimos iniciar sesión',
                    text: 'Las credenciales no son válidas.',
                    icon: 'error',
                    confirmButtonColor: 'rgb(246, 139, 127)',
                    confirmButtonText: 'Aceptar',
                });
            });

    }

    return (
        <div className='body' style={{backgroundImage: 'url("../fondoNew.png")'}}>
            <div className='loginContainer'>
                <div className='centerLogin'>
                    <div className="login">
                        <img src={ process.env.PUBLIC_URL + '/imgLogin.png' } alt="QReate" className='imgLogin'/>
                    
                        <form onSubmit={handleSubmit} >
                            <p className='textCampos'>Usuario</p>
                            <input 
                                className="inputLogin"
                                type="text"
                                name="user"
                                id="user"
                                required="required"
                                value={user}
                                onChange={(e) => setUser(e.target.value)}
                            />
                            
                            <p className='textCampos'>Contraseña</p>
                            <input 
                                className="inputLogin"
                                type="password"
                                name="password"
                                id="password"
                                required="required"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            
                            <button type="submit" className="btnIngresar btn">Ingresar</button>
                        </form>
                        <p className='version'>{versionApp}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}