
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Table, Pagination, Form, Modal} from 'react-bootstrap';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import { IoCartOutline } from "react-icons/io5";
import { FaArrowLeft, FaRegComment } from 'react-icons/fa';

export default function StockMovements() {
    const location = useLocation(); // Hook para acceder al estado de la navegación
    const [idStockParam, setIdStockParam] = useState(location.state?.idStock || null); // Recupera el idOrder o usa null por defecto

    const keyLocalStorage = 'authToken';
    const navigate = useNavigate(); // Hook de navegación

    const [showModalCommentStockMovement, setShowModalCommentStockMovement] = useState(false);
    const [showModalOrderIdStockMovement, setShowModalOrderIdStockMovement] = useState(false);

    const [comment, setComment] = useState(false);
    const [orderAsociated, setOrderAsociated] = useState(false);

    const [stockMovements, setStockMovements] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [typeFilter, setTypeFilter] = useState('');
    const [movementIdFilter, setMovementIdFilter] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [stockIdFilter, setStockIdFilter] = useState('');
    const [productFilter, setProductFilter] = useState([]);

    const [products, setProducts] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        document.title = 'QReate - Movimientos de stock';
        if(idStockParam != null){
            setStockIdFilter(idStockParam);
        }
        getPage(idStockParam);
        getAllProducts()
    }, [currentPage, isLoading]);

    const getAllProducts = () => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error(error)
            });
    }

    // Armado de la página
    const getPage = () => {
        axios.get(process.env.REACT_APP_STOCK_MOVEMENT_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                page: currentPage,
                type: typeFilter,
                idMovement: movementIdFilter,
                dateFrom: dateFrom,
                dateTo: dateTo,
                idStock: idStockParam != null ? idStockParam : stockIdFilter,
                productId: productFilter
            }
        })
        .then(response => {
            console.log(response.data.content);
            setStockMovements(response.data.content);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.number + 1);
            setCurrentNumberOfElements(response.data.numberOfElements)
            setTotalElements(response.data.totalElements)
        })
        .catch(error => {
            console.warn(error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={1} md={4}>

                    {/* Filtro por ID de Movimiento */}
                    <Col>
                        <Form.Group controlId="movementIdFilter">
                            <Form.Control
                                type="text"
                                placeholder="ID de movimiento"
                                className="filtersStyle"
                                value={movementIdFilter}
                                onChange={(e) => setMovementIdFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/* Filtro por Tipo: Selecciona entre Ingreso o Egreso */}
                    <Col>
                        <Form.Group controlId="typeFilter">
                            <Form.Select
                                value={typeFilter}
                                onChange={(e) => setTypeFilter(e.target.value)}
                                className="filtersStyle"
                            >
                                <option value="">Seleccionar tipo</option>
                                <option value="IN">Ingreso</option>
                                <option value="OUT">Egreso</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    {/* Filtro por ID de Stock */}
                    <Col>
                        <Form.Group controlId="stockIdFilter">
                            <Form.Control
                                type="text"
                                placeholder="ID de stock"
                                className="filtersStyle"
                                value={stockIdFilter}
                                onChange={(e) => setStockIdFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="productFilter">
                            <Form.Select
                                className="selectFilterProd filtersStyle"
                                value={productFilter} // El estado debe almacenar el ID del producto
                                onChange={(e) => setProductFilter(e.target.value)} // Actualiza con el ID del producto seleccionado
                            >
                                <option value=''>Seleccione producto</option>
                                {/* Opciones dinámicas de productos */}
                                {products.map((product) => (
                                    <option key={product.id} value={product.id}>
                                        {product.name} {/* Nombre visible, pero el valor es el ID */}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    {/* Filtro por Fecha Desde */}
                    <Col style={{marginTop:'1%'}}>
                        <Form.Group controlId="dateFrom" >
                            <Form.Label>Fecha Desde</Form.Label>
                            <Form.Control
                                type="date"
                                value={dateFrom}
                                className="filtersStyle"
                                onChange={(e) => setDateFrom(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/* Filtro por Fecha Hasta */}
                    <Col style={{marginTop:'1%'}}>
                        <Form.Group controlId="dateTo">
                            <Form.Label>Fecha Hasta</Form.Label>
                            <Form.Control
                                type="date"
                                className="filtersStyle"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/* Botones de Búsqueda y Limpiar */}
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0" style={{padding: '3%'}}>
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => getPage()}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanFilters()}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    };


    const cleanFilters = () => {
        setTypeFilter("");
        setMovementIdFilter('');
        setDateFrom('');
        setDateTo('');
        setStockIdFilter('');
        setIdStockParam(null);
        setProductFilter('');
        setIsLoading(true);
        getPage();
    }

    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Tipo</th>
                            <th>Cantidad</th>
                            <th>Fecha</th>
                            <th>Stock</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="6" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || stockMovements.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || stockMovements.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const getNameType = (type) => {
        switch(type){
            case "IN": return "Ingreso (+)";
            case "OUT": return "Egreso (-)";
            default: return "-"
        }
    }

    const commentStockMovement = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalCommentStockMovement} onHide={() => setShowModalCommentStockMovement(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Comentario del Movimiento
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setShowModalCommentStockMovement(false)
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <p>{comment}</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setShowModalCommentStockMovement(false)
                    }}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const OrderIdStockMovement = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalOrderIdStockMovement} onHide={() => setShowModalOrderIdStockMovement(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Pedido asociado al Movimiento
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setShowModalOrderIdStockMovement(false)
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <p>Pedido ID: {orderAsociated}</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setShowModalOrderIdStockMovement(false)
                    }}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const handleButtonClick = (id) => {
        navigate(`/orders`, { state: { idOrder: id } });
    };

    return (
        <div className="containersStockMovements">

                <>
                    <Container className="mt-3">
                        <Row>
                            <Col>
                                <h1 className="titleStock">Movimientos de stock</h1>
                            </Col>
                        </Row>
                    </Container>
                    {/* Botón para volver */}
                    <Button
                        style={{ marginLeft: '10%', marginTop: '2%' }}
                        onClick={() => navigate('/stock')}
                    >
                        <FaArrowLeft style={{ marginRight: '8px' }} /> Stock
                    </Button>

                    <div className="productsContainer">

                            <>
                                {filtersContainer()}

                                {(isLoading) ? (
                                    loadingTable()
                                ) : (
                                    <div className="m-3">
                                        <Table responsive className="table-striped">
                                            <thead className="tableHead">
                                            <tr>
                                                <th>ID movimiento</th>
                                                <th>Tipo</th>
                                                <th>Cantidad</th>
                                                <th>Fecha</th>
                                                <th>ID Stock</th>
                                                <th>Producto de Stock</th>
                                                <th>Locacion de Stock</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                stockMovements.length > 0 ? (
                                                    stockMovements.map(stockMovement => (
                                                        <tr key={stockMovement.id}>
                                                            <td className="text-alig-center fw-bold">{stockMovement.id}</td>
                                                            <td className="text-alig-center">{getNameType(stockMovement.type)}</td>
                                                            <td className="text-alig-center">{stockMovement.quantity}</td>
                                                            <td className="text-alig-center">{stockMovement.movementDate}</td>
                                                            <td className="text-alig-center">{stockMovement.stockDTO.id}</td>
                                                            <td className="text-alig-center">{stockMovement.stockDTO.product.categoryName + " - " + stockMovement.stockDTO.product.name}</td>
                                                            <td className="text-alig-center">{stockMovement.stockDTO.location.name}</td>
                                                            <td className="text-alig-center">
                                                                {stockMovement.comment != null && stockMovement.comment != '' &&
                                                                    <button className="btn btnEdit" title="Ver comentario"><FaRegComment
                                                                        className="btnEdit" size="24"
                                                                        onClick={() => {
                                                                            setComment(stockMovement.comment)
                                                                            setShowModalCommentStockMovement(true);
                                                                    }}/></button>
                                                                }
                                                                {stockMovement.orderDTO != null &&
                                                                    <button className="btn" title="Ir a pedido"><IoCartOutline
                                                                        size="27"
                                                                        onClick={() => {handleButtonClick(stockMovement.orderDTO.id)
                                                                    }}/></button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" className="text-alig-center">Aun no hay movimientos generados</td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </Table>

                                    </div>
                                )}
                                {paginationModule()}
                                {commentStockMovement()}
                                {OrderIdStockMovement()}
                            </>

                    </div>
                </>

        </div>
    );
}